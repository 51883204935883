.page {
    height: auto;
    min-height: 100vh;
    color: white;
    background: linear-gradient(180deg, rgba(1, 145, 255, 0.489) 0%, rgba(43,43,49,0) 60%);
    display: flex;
    justify-content: center;
    align-items: center;
}

object {
    position: relative;
    width: 90vw;
    height: 80vh;
    top: 50px; 
    border: none;
}

@media only screen and (max-width: 480px) {
    .page {
        text-align: center;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
    }

    object {
        max-width: 80vw;
        max-height: 60vh; 
        top: 70px;

    }

    .error {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .page a {
        text-decoration: none;
        color: rgb(255, 255, 255);
        border: 1px solid white;
        border-radius: 4px;
        padding: 4px 12px;
        margin-top: 60px;
        width: auto;
    }
    
}