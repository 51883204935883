a {
    text-decoration: none;
}

.filler {
    height: 120px;
}

.portfolioSection h1 {
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
   
}

.portfolioSection {
    background: linear-gradient(180deg, hsla(187, 100%, 50%, 0.438) 0%, rgba(43,43,49,0) 60%);
    color: white;
}

.portfolio {
    display: flex;
    align-items: center;
    padding: 10vh 10vw;
}

.portfolio h2 {
    font-size: 5rem;
}

.line {
    border: 1px solid white;
    height: 0px;
    width: 60vw;
    margin-left: 3%;
}

.allFeaturedProjects {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.featuredPorjectsHeader {
    width: 90%;
    text-align: center;
    margin: 0;
    height: min-content;
}

.featuredProjectsHeader h1 {
    font-size: 3rem;
    text-align: center;
    position: relative;
    font-weight: 400;
    margin: 60px;
    margin-bottom: 0px;
    text-transform: none;
    color: white;
}

.project {
    min-height: 50vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10% 0%;
    max-width: 70vw;
    position: relative;
    right: 30px;
    transition: background-color 0.2s ease, transform 0.2s ease;
}

.imageContainer {
    height: 30vw;
    min-height: 200px;
    width: 30vw;
    overflow: hidden;
    border: 1px solid white;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 30px;
}

.image {
    width: 80%;
    height: 80%;
    border: 1px solid white;
    border-radius: 4px;
    overflow: hidden;
}

img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.project:hover {
    transform: scale(1.05);
    cursor: pointer;
    filter: saturate(100%);
}

.projectInfo {
    height: 100%;
    width: 50%;
    display: flex;
    flex-direction: column;
}

.projectTitle {
    font-size: 2.5vw;
    color: white;
    margin-left: 2vw;
    padding: 10px;
    position: relative;
    width: fit-content;
}

h4 {
    position: relative;
    z-index: 1;
    background: none;
}

.underline {
    height: 20px;
    width: 100%;
    background-color: rgb(114, 44, 255);
    position: absolute;
    left: 5%;
    bottom: 0;
    z-index: 0;
    margin-bottom: 8px;
}

.projectDescription {
    height: fit-content;
    width: 100%;
    background-color: hsla(240, 6%, 18%, 0.647);
    border-radius: 4px;
    color: white;
    padding: .75vw 1.1vw;
}

.projectLanguages {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 100%;
    padding: 10px;
}

.lang {
    background-color: hsla(240, 6%, 18%, 0.647);
    border-radius: 10px;
    padding: 1px 8px;
    margin-right: 5px;
    margin-bottom: 4px;
    color: white;
}

@media only screen and (max-width: 480px) {
    .filler {
        height: 70px;
    }

    .portfolio {
        padding: 0;
    }

    .singleProject {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    h1 {
        text-align: center;
    }

    .project {
        min-height: 50vh;
        max-width: 90vw;
        flex-direction: column-reverse;
        align-items: center;
        position: relative;
        left: 0px;
        transition: background-color 0.2s ease, transform 0.2s ease;
    }

    .projectTitle {
        text-align: center;
        white-space: nowrap; 
        display: inline-block;
        overflow: visible;
        margin: 0;
    }

    .projectTitle h4 {
        font-size: 2rem;
        align-items: center;
    }

    .projectInfo {
        width: 95vw;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    .imageContainer {
        height: 80vw;
        width: 80vw;
        overflow: hidden;
        border: 1px solid white;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 0px;
        border: 2px solid rgb(114, 44, 255);
    }
    
    .image {
        height: 100%;
        width: 100%;
        border-radius: 4px;
        overflow: hidden;
        border: none;
        transition: background-color 0.2s ease, transform 0.2s ease;
    }

    .underline {
        height: 20px;
        width: 100%;
        background-color: rgb(114, 44, 255);
        position: relative;
        left: 15px;
        bottom: 15px;
        z-index: 0;
        margin-bottom: 8px;
    }

    .projectLanguages {
        margin-bottom: 25px;
        position: relative;
        right: 5px;
    }
}