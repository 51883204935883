.footerContainer {
    background-color: hsl(205, 30%, 8%);
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 10px;
    margin-top: 10vh;
}

.ulContainer {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 20px 0px 20px 0px;
    border-top: 1px solid rgb(187, 187, 187);
    width: 80vw;
}

ul {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
}

li {
    text-decoration: none;
    list-style: none;
    color: white;
}

.icon {
    height: 4vw;
}

.footerContainer p {
    color: white;
    font-size: .75rem;
    font-weight: 100;
    padding-bottom: 25px;
}

@media only screen and (max-width: 480px) {
    .ulContainer {
        justify-content: center;
        gap: 15px;
        width: 90vw;
    }

    .icon {
        height: 10vw;
        width: 10vw;
    }

    .footerContainer p {
        font-size: 0.6rem;
        text-align: center;
    }
}