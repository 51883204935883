.containerWrapper {
    background-color: hsl(205, 30%, 8%);
    color: white;
    padding-top: 150px;
    font-size: 1rem;
    padding-left: 11%;
    height: 100%;
}

.container {
    width: 80vw;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

h2 {
    font-size: 3rem;
    padding-bottom: 25px;
    border-bottom: 1px solid rgb(187, 187, 187);
}

.myInfo {
    padding-top: 25px;
    border-bottom: 1px solid rgb(187, 187, 187);
    height: 100%;
}

h3 {
    color: rgb(187, 187, 187);
    font-size: .8rem;
    text-transform: uppercase;
    margin-left: 7%;
}

.container p {
    padding: 12px 0px 25px 0px;
    margin-left: 7%;
}

.formContainer {
    padding-top: 25px;
    margin-left: 7%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.formTop {
    display: flex;
    justify-content: space-between;
}

.formQuestion {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: 25px;
}

.formQuestion label {
    padding-bottom: 12px;
}

.formQuestion input {
    padding: 5px 12px 5px 0px;
    background: transparent;
    border: none;
    font-size: 1rem;
    color: white;
    caret-color: white;
    width: 80%;
    display: flex;
}

.formQuestion input::placeholder {
    color: rgb(138, 138, 138);
    padding-left: 1px;
}

.formBottom {
    display: flex;
    justify-content: center;
    width: 55%;
}

.formBottom input {
    color: white;
    background-color: hsl(205, 30%, 8%);
    border: none;
    cursor: pointer;
    margin: 25px 0px 50px 0px;
    font-size: 1.25rem;
    padding-bottom: 5px;
    
    background-image: linear-gradient(#ffffff 0 0);
    background-position: 0 100%; 
    background-size: 0% 1px;
    background-repeat: no-repeat;
    transition:
      background-size 0.3s,
      background-position 0s 0.3s;
}

.formBottom input:hover {
    background-position: 100% 100%;  
    background-size: 100% 1px;
}

@media only screen and (max-width: 480px) {
    .formTop {
        flex-direction: column;
    }
}