body {
  margin: 0;
  padding: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}

.raleway-regular {
  /* <uniquifier>: Use a uniquifier for the class name
  // <weight>: Use a value from 100 to 900 */
  font-family: "Raleway", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

* {
  margin: 0;
  padding: 0;
  max-width: 100%;
  font-family: "Raleway";
  font-weight: 300;
}

html {
  background-color: hsl(205, 30%, 8%);
}

.roboto-serif-Quotes {
  font-family: "Quotes";
  font-optical-sizing: auto;
  font-weight: 100;
  font-style: italic;
  font-variation-settings:
    "wdth" 100,
    "GRAD" 0;
}