
@import url('https://fonts.googleapis.com/css2?family=Roboto+Serif:ital,opsz,wght@0,8..144,100..900;1,8..144,100..900&display=swap');

.page {
    height: auto;
    min-height: 100% !important;
    color: white;
    background-color: hsl(205, 30%, 8%);
    display: block;
}

.filler {
    height: 200px;
}

.topText {
    width: 80%;
    text-align: center;
}

.page h1 {
    text-align: center;
    position: relative;
    font-size: 4vw;
    padding-bottom: 3vw;
    font-weight: 400;
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    background-color: inherit;
}

.bannerPics {
    width: 85vw;
    margin: 3vw 3vw 10vw 3vw;
}

.bannerPicsMobile {
    display: none;
    width: 85vw;
    margin: 3vw 3vw 10vw 3vw;
}

.bannerImgContainer {
    position: relative;
}

.bannerImgContainer img {
    display: block;
}

.bannerImgContainerMobile {
    position: relative;
}

.bannerImgContainerMobile img {
    display: none;
}

.bannerImgContainer p {
    position: absolute;
    z-index: 2;
    bottom: 11vw;
    right: 4vw;
    font-size: 1vw;
    font-style: italic;
    background-color: hsla(208, 32%, 8%, 0.9);
    padding: 2px;
    border-radius: 3px;
}

.bannerImgContainerMobile p {
    position: absolute;
    z-index: 2;
    bottom: 11vw;
    right: 4vw;
    font-size: 1vw;
    font-style: italic;
    background-color: hsla(208, 32%, 8%, 0.9);
    padding: 2px;
    border-radius: 3px;
    display: none;
}

.scrollMobile {
    display: none;
}

/* ================================================================================================= */
/* The About Me Informative Section */

.aboutInfoContainer {
    background-color: white;
    color: hsl(205, 30%, 8%);
}

.me {
    display: flex;
    max-width: 100vw;
    display: flex;
    justify-content: space-evenly;
    overflow-x: hidden;
    margin-top: 5vh;
    margin-bottom: 5vh;
    min-height: 50vh;
}

.information {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.threePics {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: hsla(205, 30%, 8%, .95);
    opacity: 0; 
    transition: opacity 0.3s;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }
  
  .mainPs {
    color: white;
    text-align: center;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: opacity 1s;
    width: 90%;
    height: 90%;
    z-index: 2;
    font-size: 1.5vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .mainPs p {
    font-size: 10rem;
  }
  
  .information:hover .overlay {
    opacity: 1; 
  }
  
  .information:hover .mainPs {
    opacity: .99;
  }

.infoSection {
    width: 30%;
    padding: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.title {
    border-bottom: none;
    font-size: 5vw;
}

/* ================================================================================================= */
/* TESTIMONIALS */

.testimonialsSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 70vh;
    background-color: hsl(208, 32%, 8%);
}

.testimonial {
    display: none; 
    opacity: 0;
    transition: opacity 3s ease;
}

.testimonial.active {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 1;
    width: 80%;
}

.testimonial img {
    width: 200px;
    height: 200px;
    object-fit: cover;
    border-radius: 100%;
    padding: 35px;
}

.testimonialInfo {
    text-align: center;
    background-color: white;
    border-radius: 3px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 100%;
    max-width: 700px;
}

.quote {
    font-family: "Roboto Serif";
    font-weight: 200;
    font-style: italic;
    padding: 5px;
}

.name {
    font-weight: 600;
    padding: 5px;
}

/* ================================================================================================= */
/* CAROUSEL */

.carouselWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
}

.carouselContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 85vw;
    overflow: hidden;
}

@media only screen and (max-width: 480px) {
    .me {
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        height: 100%;
    }

    .infoSection {
        width: 80vw;
    }

    .title {
        font-size: 30px;
        padding-bottom: 5px;
        padding-top: 20px;
    }

    .mainPs {
        font-size: 15px;
    }

    .bannerPics {
        display: none;
    }

    .bannerImgContainerMobile img {
        display: block;
        margin-bottom: 200px;
        padding: 25px 0px 60px 0px;
    }

    .bannerImgContainer img {
        display: none;
    }

    .bannerImgContainerMobile p {
        display: none;
    }

    .page h1 {
        font-size: 25px;
    }

    .topText {
        width: 90%;
    }
    
    .testimonialsSection {
        height: 100vh;
    }

    .scrollMobile {
        display: block;
        position: relative;
        bottom: 180px;
    }
    
    .scrollMobile p {
        padding-bottom: 20px;
    }
}