/* OVERALL IMAGE / BANNER CONTAINER */

.loadIn {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: hsl(205, 30%, 8%);
  z-index: 5;
  animation: loading 750ms ease-in 1.5s forwards;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: visible;
}

.loadIn p {
  font-size: 5rem;
  color: white;
  animation: textAppear 700ms ease-in 150ms backwards;
}

@keyframes textAppear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes loading {
  0% {
    opacity: 1;
    border-bottom-left-radius: 0vw;
  }
  50% {
    border-bottom-left-radius: 50%;
    border-bottom-right-radius: 50%;
    transform: translateY(0%);
  }
  100% {
    border-bottom-left-radius: 50%;
    border-bottom-right-radius: 50%;
    transform: translateY(-100%);
  }
}

@keyframes webpageAppear {
  from {
    transform: translateY(400px);
    opacity: 0;
  }
  to {
    transform: translateY(0%);
    opacity: 1;
  }
}

.bannerContainer {
  width: 100vw;
  min-height: 100vh;
  position: relative;
  overflow: hidden;
  display: flex;
}

.bannerImg {
  min-height: 100vh;
  max-height: 100vh;
}

.bannerImg img {
  width: 100vw;
  min-height: 100%;
  object-fit: cover;
}

/* NAV SECTION WITH INITIALS AND LINKS */

.nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    max-width: 100%;
    animation: webpageAppear 700ms ease-out 1.5s;
    color: white;
    z-index: 1;
  }

  .navDivLeft a {
    text-decoration: none;
  }

  .navDivLeft {
    margin: 30px;
  }

  .initials {
    border: 1px solid white;
    color: white;
    border-radius: 4px;
    padding: 4px;
    font-size: 1rem;
    font-weight: 200;
    height: 35px;
    width: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    transition: 400ms;
  }

  .initials:hover {
    background-color: hsl(205, 30%, 8%);
    border-radius: 50%;
    cursor: pointer;
  }

  .navDivRight {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 30px;
  }

  .navTabs {
    display: flex;
    justify-content: center;
    font-size: 1.1rem;
    align-items: center;
    width: 100%;
  }
  
  .navTabs a {
    text-decoration: none;
    list-style: none;
    color: white;
    margin: 0px 9px;
    padding-bottom: 2px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    background-image: linear-gradient(#ffffff 0 0);
    background-position: 0 100%;
    background-size: 0% 1px;
    background-repeat: no-repeat;
    transition:
      background-size 0.3s,
      background-position 0s 0.3s;
  }

  .navTabs a:hover {
    background-position: 100% 100%;  
    background-size: 100% 1px;
  }

  a.active {
    background-color: red;
  }

  .navTabs a li {
    width: max-content;
    margin-right: 10px;
    margin-left: 10px;
  }

  .navDivRight p {
    font-size: 1.8vw;
  }

  .navDivRight a {
    text-decoration: none;
  }

/* DANNY REGAN FRONT END SECTION */
.dannyRegan {
  position: absolute;
  top: 45%;
  left: 7%;
  font-size: 3vw;
  color: white;
  animation: webpageAppear 800ms ease-out 1.5s;
}

.frontEnd {
  font-family: "Raleway";
  font-weight: 100;
}

.qualitiesContent ol {
  list-style: none;
  --height: 3.5vw;
  height: var(--height);
  line-height: var(--height);
  overflow: hidden;
}

.qualitiesContent ol li {
  animation: slide-up 17s 2.5s forwards;
}

@keyframes slide-up {
  0%, 5% {
    transform: translateY(0%);
  }

  30%, 100% {
    transform: translateY(-1500%);
  }
}

.qualitiesContent ol li span {
  font-family: "Raleway";
  font-weight: 300;
  color: #ffffff;
  transform: scale(1,2);
}

.letsWork {
  position: absolute;
  top: 45%;
  right: 6%;
  color: white;
  font-size: 2vw;
  height: 2.7vw;
  line-height: 3vw;
  padding-bottom: 5px;
  font-family: "Raleway";
  background-image: linear-gradient(#ffffff 0 0);
    background-position: 0 100%; /*OR bottom left*/
    background-size: 0% 1px;
    background-repeat: no-repeat;
    transition:
      background-size 0.3s,
      background-position 0s 0.3s;
  animation: webpageAppear 800ms ease-out 1.5s;
}

.letsWork p {
  margin: 0px 15px;
}

.letsWork:hover {
  background-position: 100% 100%;  
    background-size: 100% 1px;

}

.nameContainer {
  width: 120vw;
  overflow: hidden;
  position: absolute;
  top: 66.7vh;
  animation: webpageAppear 700ms ease-out 1.5s;
}

.nameInner {
  white-space: nowrap;
  display: flex;
}

.nameText {
  display: flex;
}

.scrollingName {
  color: white;
  font-size: 13vw;
  font-family: "Raleway", sans-serif;
  font-weight: 300;
}

.nameInner div {
  animation: scrollLeft 10s linear infinite;
}

.spacer {
  padding: 0px 3vw;
}

@keyframes scrollLeft {
  0% {
      transform: translateX(0%);
  }

  100% {
      transform: translateX(-100%);
  }
}

@keyframes scrollRight {
  0% {
      transform: translateX(-100%);
  }

  100% {
      transform: translateX(0%);
  }
}


/* Menu Navigation Bars */

.menuContainer {
  position: fixed;
  left: 10px;
  top: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 30px;
  border: 1px solid;
}

.menuContainer a {
  width: 15px;
  height: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.menuBars {
  width: 100%;
  height: 0;
  border: 1px solid red;
  z-index: 1;
}

.menuContainer a:hover {
  width: 30px;
}


@media only screen and (max-width: 480px) {
  .bannerContainer {
    max-width: 100vw;
    max-height: 100vh; 
    overflow-x: hidden;
  }
  .bannerImg {
    height: 100vh;
  }
  .nav {
    max-width: 100vw;
    font-size: 10px;
    justify-content: space-evenly;
    margin-top: 5px;
  }
  .navTabs {
    font-size: 15px;

  }
  .navDivLeft {
    margin: 30px 1px;
  }
  .initials {
    height: 30px;
    width: 30px;
    font-size: 15px;
  }
  .navDivRight {
    margin: 1px;
  }
  .navTabs a {
    margin: 0px 5px;
  }
  .navDivCenter {
    display: none;
  }
  .dannyRegan {
    top: 60%;
    left: -2px;
    font-size: 35px;
    width: 100vw;
    text-align: center;
  }
  .frontEnd {
    display: flex;
    justify-content: center;
  }
  .qualitiesContent ol {
    height: 50px;
    line-height: 50px;
  }
  .letsWork {
    display: none;
  }
  .nameContainer {
    top: 75vh;
  }
  .loadIn p {
    text-align: center;
  }
}