/* SCROLLBAR */

::-webkit-scrollbar {
    width: 0px;
  }
  
  .scrollPath {
    position: fixed;
    top: 0;
    right: 0;
    width: 10px;
    height: 100%;
    background: rgba(255, 255, 255, .05);
  }
  
  .progressbar {
    position: fixed;
    top: 0;
    right: 0;
    width: 10px;
    background: linear-gradient(to top, #0073ff, #00ccff);
  }
  
  .progressbar:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to top, rgb(187, 187, 187), white);
  }
  
  @media only screen and (max-width: 480px) {
    .scrollPath {
      display: none;
    }
    .progressbar {
      display: none;
    }
  }